/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

    /**
     * colors
     */
  
    --spanish-gray: hsl(0, 0%, 60%);
    --sonic-silver: hsl(0, 0%, 47%);
    --eerie-black: hsl(0, 0%, 13%);
    --davys-gray: hsl(0, 0%, 33%);
    --cultured: hsl(0, 0%, 93%);
    --white: hsl(0, 100%, 100%);
    --onyx: hsl(0, 0%, 27%);
  
    --ivory: #f4e8c1;
    --azul-verde: #a0c1b8;
    --bolivia: #709fb0;
    --jacaranda: #726a95;
    --malibec: #351f39;
  
    /**
     * typography
     */
  
    --fs-1: 1.563rem;
    --fs-2: 1.375rem;
    --fs-3: 1.25rem;
    --fs-4: 1.125rem;
    --fs-5: 1rem;
    --fs-6: 0.938rem;
    --fs-7: 0.875rem;
    --fs-8: 0.813rem;
    --fs-9: 0.75rem;
    --fs-10: 0.688rem;
    --fs-11: 0.625rem;
  
    --weight-300: 300;
    --weight-400: 400;
    --weight-500: 500;
    --weight-600: 600;
    --weight-700: 700;
  
    /**
     * border-radius
     */
  
    --border-radius-md: 10px;
    --border-radius-sm: 5px;
  
    /**
     * transition 
     */
  
    --transition-timing: 0.2s ease;
  
  }
  
  
  
  
  
  /*-----------------------------------*\
    #RESET
  \*-----------------------------------*/
  
  *, *::before, *::after {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  
  a { text-decoration: none; }
  
  li { list-style: none; }
  
  button {
    background: none;
    font: inherit;
    border: none;
    cursor: pointer;
  }
  
  img, ion-icon, button, a { display: block; }
  
  span { display: inline-block; }
  
  html {
    font-family: "Poppins", sans-serif;
    -ms-scroll-chaining: none;
        overscroll-behavior: contain;
  }
  
  input {
    display: block;
    width: 100%;
    font: inherit;
  }
  
  input::-webkit-input-placeholder { font: inherit; }
  
  input::-moz-placeholder { font: inherit; }
  
  input:-ms-input-placeholder { font: inherit; }
  
  input::-ms-input-placeholder { font: inherit; }
  
  input::placeholder { font: inherit; }
  
  body { background: var(--white); }
  
  /**
   * scrollbar style
   */
  
  body::-webkit-scrollbar { width: 15px; }
  
  body::-webkit-scrollbar-track {
    background: var(--white);
    border-left: 1px solid var(--cultured);
  }
  
  body::-webkit-scrollbar-thumb {
    background: hsl(0, 0%, 80%);
    border: 3px solid var(--white);
    -webkit-border-radius: 10px;
            border-radius: 10px;
  }
  
  body::-webkit-scrollbar-thumb:hover { background: hsl(0, 0%, 70%); }
  
  
  
  
  
  /*-----------------------------------*\
    #REUSED STYLE
  \*-----------------------------------*/
  
  .container { padding: 0 15px; }
  
  .has-scrollbar { padding-bottom: 5px; }
  
  .has-scrollbar::-webkit-scrollbar {
    width: 12px; /* for vertical scroll */
    height: 12px; /* for horizontal scroll */
  }
  
  .has-scrollbar::-webkit-scrollbar-thumb {
    background: transparent;
    border: 3px solid var(--white);
    -webkit-border-radius: 20px;
            border-radius: 20px;
  }
  
  .has-scrollbar:hover::-webkit-scrollbar-thumb { background: hsl(0, 0%, 90%); }
  
  .has-scrollbar::-webkit-scrollbar-thumb:hover { background: hsl(0, 0%, 80%); }
  
  .title {
    color: var(--eerie-black);
    font-size: var(--fs-5);
    font-weight: var(--weight-600);
    letter-spacing: 0.4px;
    text-transform: capitalize;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--cultured);
    margin-bottom: 30px;
  }
  
  
  /*-----------------------------------*\
    #HEADER
  \*-----------------------------------*/
  
  .header-top,
  .header-user-actions,
  .desktop-navigation-menu { display: none; }
  
  .header-main {
    padding: 20px 0;
    border-bottom: 1px solid var(--cultured);
    background-color: var(--malibec);
  }
  
  .action-btn ion-icon{
    color: var(--ivory);
  }
   
  .header-logo { margin-bottom: 20px; }
  
  .header-logo img { margin: auto; }
  
  .header-search-container { position: relative; }
  
  .header-search-container .search-field {
    font-size: var(--fs-7);
    color: var(--onyx);
    padding: 10px 15px;
    padding-right: 110px;
    border: 1px solid var(--cultured);
    -webkit-border-radius: var(--border-radius-md);
            border-radius: var(--border-radius-md);
  }
  
  .edit-field{
    font-size: var(--fs-7);
    color: var(--onyx);
    padding: 10px 15px;
    padding-right: 50px;
    border: 1px solid var(--cultured);
    -webkit-border-radius: var(--border-radius-md);
            border-radius: var(--border-radius-md);
  }
  
  .edit-description {
    font-size: var(--fs-7);
    color: var(--onyx);
    padding: 10px 15px;
    padding-right: 50px;
    border: 1px solid var(--cultured);
    -webkit-border-radius: var(--border-radius-md);
            border-radius: var(--border-radius-md);
    height:100px;
    word-wrap: break-word; /* Wrap text to the next line */
  }
  
  
  .search-field::-webkit-search-cancel-button { display: none; }
  
  .search-btn {
    background: var(--white);
    position: absolute;
    top: 50%;
    right: 2px;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    color: var(--onyx);
    font-size: 18px;
    padding: 8px 15px;
    -webkit-border-radius: var(--border-radius-md);
            border-radius: var(--border-radius-md);
    -webkit-transition: color var(--transition-timing);
    -o-transition: color var(--transition-timing);
    transition: color var(--transition-timing);
  }
  
  .search-btn:hover { color: var(--bolivia); }

  .category-btn {
    background: var(--white);
    position: absolute;
    top: 50%;
    right: 2px;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    color: var(--onyx);
    font-size: 18px;
    padding: 8px 15px;
    -webkit-border-radius: var(--border-radius-md);
            border-radius: var(--border-radius-md);
    -webkit-transition: color var(--transition-timing);
    -o-transition: color var(--transition-timing);
    transition: color var(--transition-timing);
  }
  
  .category-btn:hover { color: var(--bolivia); }

  .left-btn {
    margin-right: 30px; /* Adjust the spacing between the buttons */
  }
  
  .right-btn {
    right: 2px; /* Position the right button */
  }

  .menu-top {
    padding-bottom: 15px;
    margin-bottom: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    border-bottom: 2px solid var(--cultured);
  }
  
  .menu-top .menu-title {
    color: var(--salmon-pink);
    font-size: var(--fs-4);
    font-weight: var(--weight-600);
  }
  
  .menu-close-btn {
    color: var(--eerie-black);
    font-size: 22px;
  }
  
  .menu-close-btn ion-icon { --ionicon-stroke-width: 50px; }
  
  .menu-category .accordion-menu {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .menu-category { border-bottom: 1px solid var(--cultured); }
  
  .menu-title {
    color: var(--onyx);
    font-size: var(--fs-6);
    font-weight: var(--weight-500);
    padding: 12px 0;
  }
  
  .accordion-menu > div { font-size: 14px; }
  
  .accordion-menu ion-icon {
    color: var(--onyx);
    --ionicon-stroke-width: 90px;
  }
  
  .accordion-menu.active .add-icon,
  .accordion-menu .remove-icon { display: none; }
  
  .accordion-menu .add-icon,
  .accordion-menu.active .remove-icon { display: block; }
  
  .menu-category .submenu-category-list { margin-left: 10px; }
  
  .submenu-title {
    padding: 6px 0;
    font-size: var(--fs-6);
    color: var(--sonic-silver);
    font-weight: var(--weight-300);
  }
  
  .submenu-title:hover { color: var(--davys-gray); }
  
  .submenu-category-list {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    -webkit-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  
  .submenu-category-list.active {
    max-height: 148px;
    visibility: visible;
  }
  
  .menu-bottom .menu-category-list { margin-bottom: 20px; }
  
  .menu-bottom .menu-category { border-bottom: none; }
  
  .menu-bottom .menu-title {
    font-size: var(--fs-6);
    font-weight: var(--weight-500);
    color: var(--eerie-black);
    padding: 12px 0;
  }
  
  .accordion-menu.active .caret-back { -webkit-transform: rotate(-0.25turn); -ms-transform: rotate(-0.25turn); transform: rotate(-0.25turn); }
  
  .menu-bottom .submenu-category-list {
    border: 1px solid var(--cultured);
    -webkit-border-radius: var(--border-radius-md);
            border-radius: var(--border-radius-md);
    padding: 0 15px;
    margin-left: 0;
    -webkit-box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.05);
            box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.05);
  }
  
  .menu-bottom .submenu-category:not(:last-child) { border-bottom: 1px solid var(--cultured); }
  
  .menu-social-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10px;
  }
  
  .menu-social-container .social-link {
    background: var(--cultured);
    color: var(--eerie-black);
    font-size: 20px;
    padding: 10px;
    -webkit-border-radius: var(--border-radius-md);
            border-radius: var(--border-radius-md);
  }
  
  
      /* Style for the dropdown container */
.dropdown {
  position: relative;
}

/* Style for the button */
.action-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/* Style for the dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  padding: 8px 0;
  z-index: 1;
}

/* Style for the dropdown links */
.dropdown-content button {
  display: block;
  color: #333;
  padding: 8px 16px;
  text-decoration: none;
  text-align: center;
}

.dropdown-title {
  text-align: center;
}

.dropdown-content button:hover {
  background-color: #f1f1f1;
}


.dropdown:hover .dropdown-content {
  display: block;
}


.dropdown-content:not(:hover) {
  display: none;
}

  
  
  /*-----------------------------------*\
    #BANNER
  \*-----------------------------------*/
  
  .banner { margin: 30px 0; }
  
  .slider-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10px;
    -webkit-border-radius: var(--border-radius-md);
            border-radius: var(--border-radius-md);
    overflow: auto hidden;
    -webkit-scroll-snap-type: inline mandatory;
        -ms-scroll-snap-type: inline mandatory;
            scroll-snap-type: inline mandatory;
    overscroll-behavior-inline: contain;
  }
  
  .slider-item {
    position: relative;
    min-width: 100%;
    max-height: 450px;
    aspect-ratio: 1 / 1;
    -webkit-border-radius: var(--border-radius-md);
            border-radius: var(--border-radius-md);
    overflow: hidden;
    scroll-snap-align: start;
  }
  
  .slider-item .banner-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: right;
       object-position: right;
  }
  
  .banner-content {
    background: hsla(0, 0%, 100%, 0.8);
    position: absolute;
    bottom: 25px;
    left: 25px;
    right: 25px;
    padding: 20px 25px;
    -webkit-border-radius: var(--border-radius-md);
            border-radius: var(--border-radius-md);
  }
  
  .banner-subtitle {
    color: var(--jacaranda);
    font-size: var(--fs-7);
    font-weight: var(--weight-500);
    text-transform: capitalize;
    letter-spacing: 2px;
    margin-bottom: 10px;
  }
  
  .banner-title {
    color: var(--eerie-black);
    font-size: var(--fs-1);
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 10px;
  }
  
  .banner-text { display: none; }
  
  .banner-btn {
    background: var(--malibec);
    color: var(--white);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    font-size: var(--fs-11);
    font-weight: var(--weight-600);
    text-transform: uppercase;
    padding: 4px 10px;
    -webkit-border-radius: var(--border-radius-sm);
            border-radius: var(--border-radius-sm);
    -webkit-transition: var(--transition-timing);
    -o-transition: var(--transition-timing);
    transition: var(--transition-timing);
  }
  
  .banner-btn:hover { background: var(--eerie-black); }
  
  
  /*-----------------------------------*\
    #PRODUCT GRID
  \*-----------------------------------*/
  
  .product-main { 
    margin-top: 30px;
    margin-bottom: 30px; }
  
  .product-grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    gap: 25px;
  }
  
  .product-grid .showcase {
    border: 1px solid var(--cultured);
    -webkit-border-radius: var(--border-radius-md);
            border-radius: var(--border-radius-md);
    overflow: hidden;
    -webkit-transition: var(--transition-timing);
    -o-transition: var(--transition-timing);
    transition: var(--transition-timing);
  }
  
  .product-grid .showcase:hover { -webkit-box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.1); box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.1); }
  
  .product-grid .showcase-banner { position: relative; }
  
  .product-grid .product-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -webkit-transition: var(--transition-timing);
    -o-transition: var(--transition-timing);
    transition: var(--transition-timing);
  }
  
  .product-grid .product-img.default {
    position: relative;
    z-index: 1;
  }
  
  .product-grid .product-img.hover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
  }
  
  .product-grid .showcase:hover .product-img.hover { opacity: 1; }
  
  .product-grid .showcase:hover .product-img.default { opacity: 0; }
  
  .product-grid .showcase:hover .product-img { -webkit-transform: scale(1.1); -ms-transform: scale(1.1); transform: scale(1.1); }
  
  .product-grid .showcase-badge {
    position: absolute;
    top: 15px;
    left: 15px;
    background: var(--ocean-green);
    font-size: var(--fs-8);
    font-weight: var(--weight-500);
    color: var(--white);
    padding: 0 8px;
    -webkit-border-radius: var(--border-radius-sm);
            border-radius: var(--border-radius-sm);
    z-index: 3;
  }
  
  .product-grid .showcase-badge.angle {
    top: 8px;
    left: -29px;
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
    text-transform: uppercase;
    font-size: 11px;
    padding: 5px 40px;
  }
  
  .product-grid .showcase-badge.black { background: var(--eerie-black); }
  
  .product-grid .showcase-badge.pink { background: var(--salmon-pink); }
  
  .product-grid .showcase-actions {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    -webkit-transform: translateX(50px);
        -ms-transform: translateX(50px);
            transform: translateX(50px);
    -webkit-transition: var(--transition-timing);
    -o-transition: var(--transition-timing);
    transition: var(--transition-timing);
    z-index: 3;
  }
  
  .product-grid .showcase:hover .showcase-actions { -webkit-transform: translateX(0); -ms-transform: translateX(0); transform: translateX(0); }
  
  .product-grid .btn-del {
    background: var(--white);
    color: var(--sonic-silver);
    margin-bottom: 5px;
    border: 1px solid var(--cultured);
    padding: 5px;
    -webkit-border-radius: var(--border-radius-sm);
    border-radius: var(--border-radius-sm);
    -webkit-transition: var(--transition-timing);
    -o-transition: var(--transition-timing);
    transition: var(--transition-timing);
    visibility: hidden; 
    position: center; 
    left: 50%; 
    top: 50%; 
    transform: translate(-31%, 150%); 
  }
  
  .product-grid .showcase:hover .btn-del {
    visibility: visible;
  }
  
  .product-grid .btn-del:hover {
    background: var(--eerie-black);
    color: var(--white);
    border-color: var(--eerie-black);
  }
  
  .product-grid .btn-upd {
    background: var(--white);
    color: var(--sonic-silver);
    margin-bottom: 5px;
    border: 1px solid var(--cultured);
    padding: 5px;
    -webkit-border-radius: var(--border-radius-sm);
    border-radius: var(--border-radius-sm);
    -webkit-transition: var(--transition-timing);
    -o-transition: var(--transition-timing);
    transition: var(--transition-timing);
    visibility: hidden; 
    position: center; 
    left: 50%; 
    top: 50%; 
    transform: translate(-31%, 150%); 
  }
  
  .product-grid .showcase:hover .btn-upd {
    visibility: visible;
  }
  
  .product-grid .btn-upd:hover {
    background: var(--eerie-black);
    color: var(--white);
    border-color: var(--eerie-black);
  }
  
  .product-grid .showcase-content { padding: 15px 20px 0; }
  
  .product-grid .showcase-category {
    color: var(--bolivia);
    font-size: var(--fs-9);
    font-weight: var(--weight-500);
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  
  .product-grid .showcase-title {
    color: var(--sonic-silver);
    font-size: var(--fs-8);
    font-weight: var(--weight-300);
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-bottom: 10px;
    -webkit-transition: var(--transition-timing);
    -o-transition: var(--transition-timing);
    transition: var(--transition-timing);
  }
  
  .product-grid .showcase-title:hover { color: var(--eerie-black); }
  
  .product-grid .showcase-company {
    color: var(--azul-verde);
    font-size: var(--fs-8);
    font-weight: var(--weight-300);
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-bottom: 10px;
    -webkit-transition: var(--transition-timing);
    -o-transition: var(--transition-timing);
    transition: var(--transition-timing);
  }
  
  .product-grid .showcase-company:hover { color: var(--bolivia); }
  
  .product-grid .showcase-rating {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: var(--sandy-brown);
    margin-bottom: 10px;
  }
  
  .product-grid .price-box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    font-size: var(--fs-7);
    color: var(--eerie-black);
    margin-bottom: 10px;
  }
  
  .product-grid .price { font-weight: var(--weight-700); }
  
  .product-grid del { color: var(--sonic-silver); }
  
  /*-----------------------------------*\
    #FOOTER
  \*-----------------------------------*/
  
  footer {
    background: var(--eerie-black);
    padding: 30px 0;
  }
  
  .footer-category {
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--onyx);
  }
  
  .footer-category-title {
    color: var(--salmon-pink);
    font-size: var(--fs-6);
    font-weight: var(--weight-600);
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  
  .footer-category-box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
    row-gap: 3px;
    margin-bottom: 15px;
  }
  
  .category-box-title {
    color: var(--spanish-gray);
    font-size: var(--fs-8);
    font-weight: var(--weight-600);
    text-transform: uppercase;
  }
  
  .footer-category-link {
    position: relative;
    color: var(--sonic-silver);
    font-size: var(--fs-7);
    text-transform: capitalize;
    -webkit-transition: var(--transition-timing);
    -o-transition: var(--transition-timing);
    transition: var(--transition-timing);
  }
  
  .footer-category-link:hover { color: var(--spanish-gray); }
  
  .footer-category-link:not(:last-child)::after {
    position: absolute;
    content: '';
    top: 3px;
    right: -10px;
    background: var(--sonic-silver);
    width: 1px;
    height: 15px;
  }
  
  
  /**
   * footer nav 
   */
  
  .footer-nav {
    border-bottom: 1px solid var(--onyx);
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
  
  .footer-nav-list:not(:last-child) { margin-bottom: 20px; }
  
  .footer-nav .nav-title {
    position: relative;
    color: var(--white);
    font-size: var(--fs-7);
    text-transform: uppercase;
    margin-bottom: 15px;
    padding-bottom: 5px;
  }
  
  .footer-nav .nav-title::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--bolivia);
    width: 60px;
    height: 1px;
  }
  
  .footer-nav-item { padding: 3px 0; }
  
  .footer-nav-link,
  .footer-nav-item .content {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    color: var(--sonic-silver);
    font-size: var(--fs-7);
    text-transform: capitalize;
    -webkit-transition: var(--transition-timing);
    -o-transition: var(--transition-timing);
    transition: var(--transition-timing);
  }
  
  .footer-nav-link:hover { color: var(--bolivia); }
  
  .footer-nav-item.flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    gap: 10px;
  }
  
  .footer-nav-item .content {
    font-style: normal;
    margin-bottom: 5px;
  }
  
  .footer-nav-item .icon-box {
    color: var(--sonic-silver);
    font-size: 25px;
  }
  
  .footer-nav-item .icon-box ion-icon { --ionicon-stroke-width: 30px; }
  
  .footer-nav .social-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10px;
  }
  
  .social-link .footer-nav-link { font-size: 25px; }
  
  .footer-bottom {
    margin-bottom: 50px;
    text-align: center;
  }
  
  .payment-img {
    max-width: 335px;
    width: 100%;
    margin: auto;
    margin-bottom: 15px;
  }
  
  .copyright {
    color: var(--sonic-silver);
    font-size: var(--fs-8);
    font-weight: var(--weight-500);
    text-transform: capitalize;
    letter-spacing: 1.2px;
  }
  
  .copyright a {
    display: inline;
    color: inherit;
  }
  
  
  
  
  
  /*-----------------------------------*\
    #RESPONSIVE
  \*-----------------------------------*/
  
  
  /**
   * responsive larger than 480px screen
   */
  
  @media (min-width: 480px) {
  
    /**
     * #CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography 
       */
  
      --fs-1: 1.875rem;
  
    }
  
  
  
    /**
     * #HEADER
     */
  
    .header-top {
      display: block;
      padding: 10px 0;
      border-bottom: 1px solid var(--cultured);
    }
  
    .header-social-container,
    .header-top-actions { display: none; }
  
    .header-alert-news {
      color: var(--sonic-silver);
      font-size: var(--fs-9);
      text-transform: uppercase;
    }
  
    .header-alert-news b { font-weight: var(--weight-500); }
  
    .header-main { padding: 25px 0; }
  
    .mobile-bottom-navigation {
      -webkit-border-top-left-radius: var(--border-radius-md);
              border-top-left-radius: var(--border-radius-md);
      -webkit-border-top-right-radius: var(--border-radius-md);
              border-top-right-radius: var(--border-radius-md);
    }
  
  
  
    /**
     * #BANNER
     */
  
    .slider-item { aspect-ratio: 5 / 3; }
  
    .banner-content {
      top: 50%;
      right: auto;
      bottom: auto;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      max-width: 320px;
    }
  
    .banner-subtitle { --fs-7: 1rem; }
  
    .banner-text {
      display: block;
      color: var(--sonic-silver);
      font-size: var(--fs-7);
      font-weight: var(--weight-500);
      margin-bottom: 10px;
    }
  
    .banner-text b { font-size: var(--fs-2); }
  
    .banner-btn { padding: 7px 20px; }
  
  
  
    /**
     * #PRODUCT 
     */
  
    .product-grid {
      -ms-grid-columns: 1fr 30px 1fr;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
    }
  
  
  
    /**
     * #FOOTER
     */
  
    .copyright { --fs-8: 0.875rem; }
  
  }
  
  
  
  
  
  /**
   * responsive larger than 570px screen
   */
  
  @media (min-width: 570px) {
  
    /**
     * #HEADER
     */
  
    .header-top .container,
    .header-main .container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
    }
  
    .header-logo { margin-bottom: 0; }
  
    .header-top-actions {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      gap: 10px;
    }
  
    .header-top-actions select {
      border: none;
      display: block;
      min-width: 80px;
      padding: 5px 0;
      font: inherit;
      color: var(--sonic-silver);
      font-size: var(--fs-8);
      text-transform: uppercase;
      cursor: pointer;
      -webkit-transition: var(--transition-timing);
      -o-transition: var(--transition-timing);
      transition: var(--transition-timing);
    }
  
    .header-top-actions select:hover { color: var(--eerie-black); }
  
    .header-search-container { min-width: 300px; }
  
  
  
    /**
     * #BANNER
     */
  
    .slider-item { aspect-ratio: 4 / 2; }
  
    .banner-content { background: none; }
  
  
  
  
  
    /**
     * #PRODUCT
     */
  
    .product-featured .showcase-img {
      max-width: 450px;
      margin: auto;
    }
  
    .product-featured .countdown { gap: 20px; }
  
  }
  
  
  
  
  
  /**
   * responsive larger than 768px screen
   */
  
  @media (min-width: 768px) {
  
    /**
     * #CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography 
       */
  
      --fs-1: 2.375rem;
  
    }
  
  
  
    /**
     * #BASE 
     */
  
    html { font-size: 17px; }
  
  
  
    /**
     * #REUSED STYLE 
     */
  
    .container {
      max-width: 750px;
      margin: auto;
    }
  
  
  
    /**
     * #HEADER 
     */
  
    .header-main .container { gap: 80px; }
  
    .header-search-container { -webkit-box-flex: 1; -webkit-flex-grow: 1; -ms-flex-positive: 1; flex-grow: 1; }
  
  
  
    /**
     * #BANNER 
     */
  
    .slider-item {
      aspect-ratio: auto;
      height: 350px;
    }
  
    .banner-content { max-width: 380px; }
  
    .banner-subtitle { --fs-7: 1.25rem; }
  
    .banner-text { --fs-7: 1.125rem; }
  
    .banner-text b { --fs-2: 1.875rem; }
  
    .banner-btn { --fs-11: 0.75rem; }
  
  
  
  
  
    /**
     * #PRODUCT 
     */
  
    
    .product-featured .showcase {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      gap: 20px;
    }
  
    .product-featured .showcase-img { max-width: -webkit-fit-content; max-width: -moz-fit-content; max-width: fit-content; }
  
    .product-featured .showcase-content {
      margin-top: 0;
      min-width: -webkit-calc(100% - 345px);
      min-width: calc(100% - 345px);
    }
  
  
  
    /**
     * #FOOTER 
     */
  
    .footer-nav .container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      row-gap: 50px;
      -webkit-column-gap: 20px;
         -moz-column-gap: 20px;
              column-gap: 20px;
    }
  
    .footer-nav-list {
      min-width: -webkit-calc(33.33% - 15px);
      min-width: calc(33.33% - 15px);
      width: -webkit-calc(33.33% - 15px);
      width: calc(33.33% - 15px);
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
    }
  
    .footer-nav-list:not(:last-child) { margin-bottom: 0; }
  
  }
  
  
  
  
  
  /**
   * responsive larger than 1024px screen
   */
  
  @media (min-width: 1024px) {
  
    /**
     * #CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography 
       */
  
      --fs-1: 2.625rem;
      --fs-2: 1.125rem;
  
    }
  
  
  
    /**
     * #REUSED STYLE 
     */
  
    .container { max-width: 980px; }
  
  
  
    /**
     * #HEADER 
     */
  
    .header-social-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      gap: 5px;
    }
  
    .header-social-container .social-link {
      padding: 5px;
      background: hsl(0, 0%, 95%);
      -webkit-border-radius: var(--border-radius-sm);
              border-radius: var(--border-radius-sm);
      color: var(--sonic-silver);
      -webkit-transition: var(--transition-timing);
      -o-transition: var(--transition-timing);
      transition: var(--transition-timing);
    }
  
    .header-social-container .social-link:hover {
      background: var(--salmon-pink);
      color: var(--white);
    }
  
    .header-user-actions {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      gap: 15px;
    }
  
    .header-user-actions .action-btn {
      position: relative;
      font-size: 35px;
      color: var(--onyx);
      padding: 5px;
    }
  
    .header-user-actions .count {
      position: absolute;
      top: -2px;
      right: -3px;
      background: var(--bittersweet);
      color: var(--white);
      font-size: 12px;
      font-weight: var(--weight-500);
      line-height: 1;
      padding: 2px 4px;
      -webkit-border-radius: 20px;
              border-radius: 20px;
    }
  
    .desktop-navigation-menu { display: block; }
  
    .desktop-menu-category-list {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      gap: 30px;
    }
  
    .desktop-menu-category-list .menu-category:not(:nth-child(2)) { position: relative; }
  
    .desktop-menu-category-list .menu-category > .menu-title {
      position: relative;
      color: var(--onyx);
      font-size: var(--fs-7);
      font-weight: var(--weight-600);
      text-transform: uppercase;
      padding: 15px 0;
      -webkit-transition: var(--transition-timing);
      -o-transition: var(--transition-timing);
      transition: var(--transition-timing);
    }
  
    .desktop-menu-category-list .menu-category > .menu-title:hover { color: var(--jacaranda); }
    
    .desktop-menu-category-list .menu-category > .menu-title::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: var(--jacaranda);
      -webkit-transform: scaleX(0);
          -ms-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: left;
          -ms-transform-origin: left;
              transform-origin: left;
      -webkit-transition: var(--transition-timing);
      -o-transition: var(--transition-timing);
      transition: var(--transition-timing);
    }
  
    .desktop-menu-category-list .menu-category > .menu-title:hover::after { -webkit-transform: scaleX(1); -ms-transform: scaleX(1); transform: scaleX(1); }
  
    .dropdown-panel {
      position: absolute;
      top: 100%;
      left: 0;
      background: var(--white);
      width: 100%;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr 30px 1fr 30px 1fr 30px 1fr;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
      padding: 30px;
      border: 1px solid var(--cultured);
      -webkit-box-shadow: 0 3px 5px hsla(0, 0%, 0%, 0.1);
              box-shadow: 0 3px 5px hsla(0, 0%, 0%, 0.1);
      -webkit-border-radius: var(--border-radius-md);
              border-radius: var(--border-radius-md);
      -webkit-transform: translateY(50px);
          -ms-transform: translateY(50px);
              transform: translateY(50px);
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      -webkit-transition: var(--transition-timing);
      -o-transition: var(--transition-timing);
      transition: var(--transition-timing);
      z-index: 5;
    }
  
    .desktop-menu-category-list .menu-category:hover > .dropdown-panel {
      -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  
    .dropdown-panel-list .menu-title a {
      color: var(--onyx);
      font-size: var(--fs-7);
      font-weight: var(--weight-600);
      padding-bottom: 10px;
      border-bottom: 1px solid var(--cultured);
      margin-bottom: 10px;
    }
  
    .panel-list-item a {
      color: var(--sonic-silver);
      font-size: var(--fs-7);
      text-transform: capitalize;
      -webkit-transition: var(--transition-timing);
      -o-transition: var(--transition-timing);
      transition: var(--transition-timing);
    }
  
    .panel-list-item a:hover { color: var(--jacaranda); }
  
    .panel-list-item a { padding: 4px 0; }
  
  
    .dropdown-list {
      position: absolute;
      top: 100%;
      left: 0;
      width: 200px;
      background: var(--white);
      padding: 20px 0;
      -webkit-border-radius: var(--border-radius-md);
              border-radius: var(--border-radius-md);
      border: 1px solid var(--cultured);
      -webkit-box-shadow: 0 3px 5px hsla(0, 0%, 0%, 0.1);
              box-shadow: 0 3px 5px hsla(0, 0%, 0%, 0.1);
      -webkit-transform: translateY(50px);
          -ms-transform: translateY(50px);
              transform: translateY(50px);
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      -webkit-transition: var(--transition-timing);
      -o-transition: var(--transition-timing);
      transition: var(--transition-timing);
      z-index: 5;
    }
  
    .desktop-menu-category-list .menu-category:hover > .dropdown-list {
      -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  
    .dropdown-list .dropdown-item a {
      color: var(--sonic-silver);
      font-size: var(--fs-7);
      text-transform: capitalize;
      padding: 4px 20px;
      -webkit-transition: var(--transition-timing);
      -o-transition: var(--transition-timing);
      transition: var(--transition-timing);
    }
  
    .dropdown-list .dropdown-item a:hover { color: var(--salmon-pink); }
  
  
  
  
    /**
     * #BANNER 
     */
  
    .banner { margin-top: 0; }
  
    .slider-item { height: 380px; }
  
    .banner-content {
      left: 75px;
      max-width: 400px;
    }
  
    .banner-subtitle { --fs-7: 1.625rem; }
  
    .banner-text { --fs-7: 1.375rem; }
  
    .banner-btn { --fs-11: 0.875rem; }
  
  
  
  
    /**
     * #PRODUCT 
     */
  
    .product-container .container {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
          -ms-flex-align: start;
              align-items: flex-start;
      gap: 30px;
      margin-bottom: 30px;
    }
  
  
    .product-box { min-width: -webkit-calc(75% - 15px); min-width: calc(75% - 15px); }
  
    .product-featured .countdown-content { padding: 5px 10px; }
  
    .product-grid { -ms-grid-columns: (1fr)[3]; grid-template-columns: repeat(3, 1fr); }
  
  
  
    /**
     * #FOOTER
     */
  
    .footer-nav-list {
      min-width: -webkit-calc(20% - 16px);
      min-width: calc(20% - 16px);
      width: -webkit-calc(20% - 16px);
      width: calc(20% - 16px);
    }
  
    .footer-nav-list:last-child { display: none; }
  
    .footer-bottom { margin-bottom: 0; }
  
  }
  
  
  
  
  
  /**
   * responsive larger than 1200px screen
   */
  
  @media (min-width: 1200px) {
  
    /**
     * #REUSED STYLE 
     */
  
    .container { max-width: 1200px; }
  
  
  
    /**
     * #HEADER
     */
  
    .desktop-menu-category-list { gap: 45px; }
  
  
  
    /**
     * #BANNER
     */
  
    .slider-item:last-child .banner-img { -o-object-position: top; object-position: top; }
  
  
  
    /**
     * #PRODUCT
     */
  
    .product-featured .showcase > div { min-width: -webkit-calc(50% - 10px); min-width: calc(50% - 10px); }
  
    .product-featured .display-number { --fs-5: 1.125rem; }
  
    .product-grid { -ms-grid-columns: (1fr)[4]; grid-template-columns: repeat(4, 1fr); }
  
  
    /**
     * #FOOTER
     */
  
    footer { padding-top: 50px; }
  
    .footer-category {
      margin-bottom: 50px;
      padding-bottom: 35px;
    }
  
    .footer-nav { 
      padding-bottom: 50px; 
    }
  
  }
  footer {
    display: block;
    margin-top: 20px; /* Adjust this value as needed */
  }
  
  
  
  
  /**
   * responsive larger than 1400px screen
   */
  
  @media (min-width: 1400px) {
  
    /**
     * #BASE 
     */
  
    html { font-size: 18px; }
  
  
  
    /**
     * #REUSED STYLE 
     */
  
    .container { max-width: 1350px; }
  
  
  
    /**
     * #BANNER
     */
  
    .slider-item { height: 450px; }
  
    .banner-content {
      left: 110px;
      max-width: 460px;
    }
  
  }
  
  .about-paragraph {
      margin-bottom: 30px;
    }
  
  /**
     * #CREATE LISTING CONTENT
     */
  .content {
    padding: 20px;
    text-align: center;
  }
  
  .content h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .content p {
    font-size: 16px;
    margin-bottom: 40px;
  }
  
  .listing-form {
    max-width: 400px; /* Adjust the width as desired */
    margin: 0 auto;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
  }
  
  .listing-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .listing-form textarea,
  .listing-form input[type="text"],
  .listing-form input[type="number"],
  .listing-form input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .listing-form textarea {
    height: 150px; /* Adjust the height as desired */
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
  }
  
  .form-column {
    flex-basis: 48%; /* Adjust the width as desired */
  }
  
  .listing-form button[type="submit"] {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .listing-form button[type="submit"]:hover {
    background-color: #45a049;
  }
  
      .overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
      }
  
      .popup-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
        text-align: center;
      }
  
      .popup-buttons {
        margin-top: 20px;
      }
  
      .popup-buttons button {
        margin: 0 10px;
        padding: 10px 20px;
        background-color: #4caf50;
        color: #fff;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
      }
  
      .popup-buttons button:hover {
        background-color: #45a049;
      }
  
    
  
  /**
     * #BOOKING
     */
     .booking-container {
      max-width: 600px;
      margin: 0 auto;
      padding: 20px;
      background-color: #f9f9f9;
      border: 1px solid #ddd;
      border-radius: 5px;
    }
    
    .booking-container h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }
    
    .booking-container form {
      display: grid;
      grid-gap: 20px;
    }
    
    .booking-container .form-group {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
    
    .booking-container label {
      font-weight: bold;
    }
    
    .booking-container input[type="text"],
    .booking-container input[type="email"],
    .booking-container input[type="tel"],
    .booking-container textarea {
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      width: 100%;
    }
    
    .booking-container button[type="submit"] {
      padding: 10px 20px;
      background-color: #1e87f0;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
    
    .booking-container button[type="submit"]:hover {
      background-color: #146ac8;
    }
    
    .booking-container .error {
      border: 1px solid #ff0000;
    }
    
    @media (max-width: 768px) {
      .booking-container {
        max-width: 100%;
      }
    }
    
    .dropdown {
          position: relative;
          display: inline-block;
        }
    
        .dropdown-content {
          display: none;
          position: absolute;
          background-color: #f9f9f9;
          min-width: 200px;
          border: 1px solid #ddd;
          z-index: 1;
        }
    
        .dropdown-content a {
          color: black;
          padding: 8px 12px;
          text-decoration: none;
          display: block;
        }
    
        .dropdown:hover .dropdown-content {
          display: block;
        }
  
        /* CSS for the popup */
  .popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
  }
  
  .popup h3 {
    margin-top: 0;
  }
  
  .popup button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .popup button:hover {
    background-color: #45a049;
  }