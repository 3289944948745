/* Import Google font - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

span{
  color:white;
}

.header {
  position: fixed;
  height: 80px;
  width: 100%;
  z-index: 100;
  padding: 0 20px;
}

.nav {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
}

.nav,
.nav_item {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.nav_link {
  color: white;
}

.nav_item {
  column-gap: 25px;
}

.nav_link:hover {
  color: #d9d9d9;
}

/* Home */
.home {
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: url('./localize.svg');
  background-size: cover;
  background-position: center;
}

.home::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease-out;
}

.home.show::before {
  opacity: 1;
  pointer-events: auto;
}

/* Form */
.form_container {
  position: fixed;
  max-width: 320px;
  width: 100%;
  top: 50%;
  right: 250px;
  transform: translate(0, -50%);
  z-index: 101;
  background: rgba(128, 128, 128, 0.2); 
  padding: 25px;
  border-radius: 0px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease-out;
}

.home.show .form_container {
  opacity: 1;
  pointer-events: auto;
  transform: translate(0, -50%);
}

.signup_form {
  display: none;
}

.form_container.active .signup_form {
  display: block;
}

.form_container.active .login_form {
  display: none;
}

.form_container h2 {
  font-size: 22px;
  color: white;
  text-align: center;
}

.input_box {
  position: relative;
  margin-top: 30px;
  width: 100%;
  height: 40px;
}

.input_box input {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  padding: 0 30px;
  color: #333;
  transition: all 0.2s ease;
  border-bottom: 1.5px solid #aaaaaa;
  border-radius: 5px;
}

.input_box input:focus {
  border-color: #7d2ae8;
}

.input_box i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #707070;
}

.input_box i.email,
.input_box i.password {
  left: 0;
}

.input_box input:focus ~ i.email,
.input_box input:focus ~ i.password {
  color: #7d2ae8;
}

.input_box i.pw_hide {
  right: 0;
  font-size: 18px;
  cursor: pointer;
}

.form_container a {
  color: #7d2ae8;
  font-size: 12px;
}

.form_container a:hover {
  text-decoration: underline;
}

.form_container .button {
  background: #7d2ae8;
  margin-top: 30px;
  width: 100%;
  padding: 10px 0;
  border-radius: 10px;
}

.login_signup {
  font-size: 12px;
  text-align: center;
  margin-top: 15px;
}
